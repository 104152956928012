const heroTitle = {
  title : {
    second: "MEMBERS"
  },
  desc : [
    { info: "members-desc" },
    { info: "members-desc2" },
  ]
}

export {
  heroTitle
}